import React from 'react';

import { RouteDefinition } from './types';

export const UNAUTHORIZED_URL = '/unauthorized';
export const UNDER_CONSTRUCTION_URL = '/under-construction';
export const VERRIFIC_BIZ_URL = 'https://www.verrific.biz/';
export const SELF_SERVE_URL = '/verification';
export const REGISTER_DENTAL_OFFICE_URL = '/register-do';
export const MANAGEMENT_URL = '/management';
export const SCHEDULE_URL = '/schedule';
export const PAYMENT_URL = '/payment';
export const PAYMENT_SETUP_URL = '/payment/setup';
export const PAYMENT_SETTINGS_URL = '/payment/settings';

export const DENTAL_OFFICE_SETTINGS = 'dental-office-settings';
export const DENTAL_OFFICE_SETTINGS_URL = `/${DENTAL_OFFICE_SETTINGS}`;
export const routes: RouteDefinition[] = [
  {
    label: 'root',
    path: '/',
    Component: React.lazy(
      async () => import('../Screens/Public/AutoRedirectOnRoot'),
    ),
    private: false,
    default: true,
  },
  {
    label: 'login',
    path: '/login',
    Component: React.lazy(async () => import('../Screens/Public/LoginPage')),
    private: false,
    default: false,
  },
  {
    label: 'logout',
    path: '/logout',
    Component: React.lazy(async () => import('../Screens/Public/LogoutPage')),
    private: false,
    default: false,
  },
  {
    label: 'unauthorized',
    path: UNAUTHORIZED_URL,
    Component: React.lazy(
      async () => import('../Screens/Public/UnauthorizedPage'),
    ),
    private: false,
    default: false,
  },
  {
    label: 'under-construction',
    path: UNDER_CONSTRUCTION_URL,
    Component: React.lazy(
      async () => import('../Screens/Public/UnderConstructionPage'),
    ),
    private: false,
    default: false,
  },
  {
    label: 'handleLogin',
    path: '/handleLogin',
    Component: React.lazy(
      async () => import('../Screens/Public/HandleLoginPage'),
    ),
    private: false,
    default: false,
  },
  {
    label: 'sessionEnd',
    path: '/sessionEnd',
    Component: React.lazy(
      async () => import('../Screens/Public/SessionEndPage'),
    ),
    private: false,
    default: false,
  },
  {
    label: 'refreshToken',
    path: '/refreshToken',
    Component: React.lazy(
      async () => import('../Screens/Public/RefreshTokenPage'),
    ),
    private: false,
    default: false
  },
  {
    label: 'userGuide',
    path: '/user-guide',
    Component: React.lazy(
      async () => import('../Screens/Public/UserGuidePdf'),
    ),
    private: false,
    default: false,
  },
  {
    label: 'stripe-settings',
    path: '/payment-settings',
    Component: React.lazy(
      async () => import('../Screens/Private/Stripe'),
    ),
    private: true,
    default: false,
    roles: ['superadministrator', 'staffmanager', 'staff']
  },
  // Commented because feature is not ready for release!
  {
    label: 'past-verifications',
    path: '/past-verifications',
    Component: React.lazy(
      async () => import('../Screens/Private/PastVerifications'),
    ),
    private: true,
    default: false,
    roles: ['staffmanager', 'staff']
  },
  {
    label: 'verification',
    path: SELF_SERVE_URL,
    Component: React.lazy(
      async () => import('../Screens/Private/DentalOffice'),
    ),
    private: true,
    default: false,
    roles: ['superadministrator', 'staffmanager', 'staff', 'admin', 'agent'],
    subpaths: [
      // {
      //   label: 'insurance_by_id',
      //   path: '/:id',
      //   Component: React.lazy(
      //     async () => import('../Screens/Private/InsurancePage/InsurancePage'),
      //   ),
      //   private: true,
      //   default: false,
      //   roles: [
      //     'admin',
      //     'agent',
      //     'superadministrator',
      //     'staffmanager',
      //     'staff',
      //   ],
      // },
      {
        label: 'insurance_by_id',
        path: '/:id',
        Component: React.lazy(
          async () => import('../Screens/Private/VerificationPage'),
        ),
        private: true,
        default: false,
        roles: [
          'admin',
          'agent',
          'superadministrator',
          'staffmanager',
          'staff',
        ],
      },
      {
        label: 'insurance_source_data_by_id',
        path: '/:id/source',
        Component: React.lazy(
          async () =>
            import('../Screens/Private/InsurancePage/VerificationSource'),
        ),
        private: true,
        default: false,
        roles: [
          'admin',
          'agent',
          'superadministrator',
          'staffmanager',
          'staff',
        ],
      },
    ],
  },
  {
    label: 'reverification',
    path: '/reverification',
    Component: React.lazy(
      async () => import('../Screens/Private/DentalOffice'),
    ),
    private: true,
    default: false,
    roles: ['superadministrator', 'staffmanager', 'staff'],
    subpaths: [
      {
        label: 'insurance_by_id',
        path: '/:id',
        Component: React.lazy(
          async () => import('../Screens/Private/InsurancePage/InsurancePage'),
        ),
        private: true,
        default: false,
        roles: [
          'admin',
          'agent',
          'superadministrator',
          'staffmanager',
          'staff',
        ],
      },
    ],
  },
  {
    label: 'register-do',
    path: REGISTER_DENTAL_OFFICE_URL,
    Component: React.lazy(
      async () => import('../Screens/Private/RegisterDentalOffice'),
    ),
    private: true,
    default: false,
    roles: ['superadministrator', 'doregistration'],
  },
  {
    label: 'termsOfService',
    path: '/terms',
    Component: React.lazy(
      async () => import('../Screens/Public/TermsOfService'),
    ),
    private: false,
    default: false,
  },
  {
    label: 'customization',
    path: '/customization',
    Component: React.lazy(
      async () => import('../Screens/Private/Cuztomization/CustomizationPage'),
    ),
    private: true,
    default: false,
    subpaths: [
      {
        label: 'form',
        path: '/verification',
        Component: React.lazy(
          async () =>
            import(
              '../Screens/Private/Cuztomization/VerificationCustomization'
            ),
        ),
        private: true,
        default: false,
        roles: ['staffmanager'],
      },
    ],
    roles: ['staffmanager'],
  },
  {
    label: DENTAL_OFFICE_SETTINGS,
    path: DENTAL_OFFICE_SETTINGS_URL,
    Component: React.lazy(
      async () => import('../Screens/Private/DentalOfficeSettings/DentalOfficeSettingsPage'),
    ),
    private: true,
    default: false,
    roles: ['staffmanager'],
  },
  {
    label: 'schedule',
    path: SCHEDULE_URL,
    Component: React.lazy(
      async () => import('../Screens/Private/Management/Schedule/SchedulePage'),
    ),
    private: true,
    default: false,
    roles: ['superadministrator', 'staffmanager', 'staff'],
    subpaths: [
      {
        label: 'dated schedule',
        path: '/:date',
        Component: React.lazy(
          async () => import('../Screens/Private/Management/Schedule/SchedulePage'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator', 'staffmanager', 'staff'],
      }
    ]
  },
  {
    label: 'payment',
    path: PAYMENT_URL,
    Component: React.lazy(
      async () => import('../Screens/Private/Payment/PaymentPage'),
    ),
    private: true,
    default: false,
    roles: ['staff', 'staffmanager'],
    subpaths: [
      {
        label: 'payment verify',
        path: '/verify',
        Component: React.lazy(
          async () => import('../Screens/Private/Payment/SetupVerify'),
        ),
        private: true,
        default: false,
        roles: ['staffmanager']
      },
      {
        label: 'payment setup',
        path: '/setup',
        Component: React.lazy(
          async () => import('../Screens/Private/Payment/SetupPage'),
        ),
        private: true,
        default: false,
        roles: ['staffmanager']
      },
      {
        label: 'payment settings',
        path: '/settings',
        Component: React.lazy(
          async () => import('../Screens/Private/Payment/SettingsPage'),
        ),
        private: true,
        default: false,
        roles: ['staffmanager']
      }
    ]
  },
  {
    label: 'management',
    path: '/management',
    Component: React.lazy(
      async () => import('../Screens/Private/Management/Management'),
    ),
    private: true,
    default: false,
    subpaths: [
      {
        label: 'global customization',
        path: '/customization',
        Component: React.lazy(
          async () =>
            import(
              '../Screens/Private/Cuztomization/VerificationCustomization'
            ),
        ),
        private: true,
        default: false,
        roles: ['admin', 'superadministrator'],
      },
      {
        label: 'form',
        path: '/form',
        Component: React.lazy(
          async () => import('../Screens/Private/Management/FormDesigner'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator'],
      },
      {
        label: 'form templates',
        path: 'form-templates',
        Component: React.lazy(
          async () =>
            import('../Screens/Private/Management/FormTemplateDesigner'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator'],
      },
      {
        label: 'carriers',
        path: '/carriers',
        Component: React.lazy(
          async () => import('../Screens/Private/Management/Carriers/Carriers'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator'],
      },
      {
        label: 'rules',
        path: '/rules',
        Component: React.lazy(
          async () =>
            import('../Screens/Private/Management/RulesDesigner/RulesDesigner'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator'],
      },
      {
        label: 'verification',
        path: '/verification',
        Component: React.lazy(
          async () =>
            import(
              '../Screens/Private/Management/TicketDebugger/TicketDebugger'
            ),
        ),
        private: true,
        default: false,
        roles: ['admin', 'superadministrator'],
      },
      {
        label: 'requesters',
        path: '/requesters',
        Component: React.lazy(
          async () =>
            import('../Screens/Private/Management/Requesters/Requesters'),
        ),
        private: true,
        default: false,
        roles: ['admin', 'superadministrator'],
      },
      {
        label: 'keys',
        path: '/keys',
        Component: React.lazy(
          async () => import('../Screens/Private/Management/Keys/Keys'),
        ),
        private: true,
        default: false,
        roles: ['admin', 'superadministrator'],
      },
      {
        label: 'integrations',
        path: '/integrations',
        Component: React.lazy(
          async () =>
            import(
              '../Screens/Private/Management/IntegrationsManagement/Integrations'
            ),
        ),
        private: true,
        default: false,
        roles: ['admin', 'superadministrator'],
      },
      {
        label: 'verifications',
        path: '/verifications',
        Component: React.lazy(
          async () =>
            import('../Screens/Private/Management/TicketList/TicketList'),
        ),
        private: true,
        default: false,
        roles: ['agent', 'admin', 'superadministrator'],
      },
      {
        label: 'bug-reports',
        path: '/bugs',
        Component: React.lazy(
          async () => import('../Screens/Private/Management/Bugs/BugsListPage'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator']
      },
      {
        label: 'bug-reports-id',
        path: '/bugs/:id',
        Component: React.lazy(
          async () => import('../Screens/Private/Management/Bugs/Popups/BugDrawer'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator'],
      },
      {
        label: 'administrative-mode',
        path: '/correction',
        Component: React.lazy(
          async () => import('../Screens/Private/Management/AdministrativeMode/AdministrativeMode'),
        ),
        private: true,
        default: false,
        roles: ['goduser'],
      },
      {
        label: 'do-management',
        path: '/do-management',
        Component: React.lazy(
          async () =>
            import('../Screens/Private/Management/DOManagement/DOManagement'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator'],
      },
      {
        label: 'features',
        path: '/features',
        Component: React.lazy(
          async () =>
            import('../Screens/Private/Management/Features/Features'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator'],
      },
      {
        label: 'stats',
        path: '/stats',
        Component: React.lazy(
          async () => import('../Screens/Private/Management/Stats/StatsPage'),
        ),
        private: true,
        default: false,
        roles: ['superadministrator', 'staffmanager', 'staff'],
      },
    ],
    roles: ['agent', 'admin', 'superadministrator'],
  },
];
