import { isArray } from 'lodash';
import {
  Auth0Verrific,
  compare,
} from 'verrific-plus-schema';

import { handleErrorGlobally, handleUnauthorized } from '../Errors/CommonErrorsHandler';
import { toast } from 'react-toastify';

type TRequestType = 'POST' | 'PUT' | 'GET' | 'OPTIOS' | 'DELETE' | 'PATCH' | 'HEAD';

const PATH = process.env.REACT_APP_PATH ? process.env.REACT_APP_PATH : '';

export interface ICall {
 type: TRequestType ,
 path: string,
 body?: { [key: string]: string | number | boolean | Record<string, string | number | boolean | string[] | undefined> | string[] | undefined } | undefined,
 headers?: { [key: string]: string },
 forceHttp?: boolean,
 cached?: boolean,
}

export interface ICallCached extends ICall {
  cachedResponse?: IResponse<any>;
}

export interface IResponse<T> {
  body?: T,
  error?: { [key: string]: string } | string,
  message?: string,
  rawBody?: string,
  statusCode: number,
  files?: Blob;
  eTag?: string | null;
}

export type IPaginableResponse<T> = T & {
  pagination: {
    limit: number;
    page: number;
    totalCount: number;
  };
};

export interface IRequestMock {
  type: TRequestType,
  path: string,
  fn: <T>(call: ICall) => Promise< IResponse<T> >
}

class CommunicationService{
  private constructor (){ /* EMPTY */ }
  private static _INSTANCE: CommunicationService;
  public static getInstance(): CommunicationService{
    if(!CommunicationService._INSTANCE){
      CommunicationService._INSTANCE = new CommunicationService;
    }
    return CommunicationService._INSTANCE;
  }
  private authToken: string | null = null;
  private mocks: IRequestMock[] = []
  private cache: ICallCached[] = [];

  private getMock(type: TRequestType, path: string): IRequestMock | undefined {
    return this.mocks.find(mock => mock.path === path && mock.type === type);
  }
  
  public parseQuery(obj: {[key: string]: string | number | boolean | Record<string, string | number | boolean | string[] | undefined> | string[] | undefined}): string {
    let result = '';
    if (Object.keys(obj).length !== 0) {
      const size = Object.keys(obj).length;
      result += '?';
      Object.entries(obj).forEach(([key, value]: [string, string | number | boolean | Record<string, string | number | boolean | string[] | undefined> | string[] | undefined], index: number) => {
        if(typeof value === 'object'){
          result += `${key}=${Object.entries(value).forEach(([key, value]:[string, string | number | boolean | string[] | undefined], index: number) => {
            result += `${key}=${value}`;
            if (index < size - 1) {
              result += '&';
            }
          })}`;
        } else {
          result += `${key}=${value}`;
          if (index < size - 1) {
            result += '&';
          }
        }
      });
    }
    return result;
  }

  public setAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  public mockMethod(type: TRequestType, path: string, fn: (call: ICall) => Promise< IResponse<any> >) {
    this.mocks.push({
      type,
      path,
      fn
    });
  }

  public async secureCall<T>({ type, path, body, headers, forceHttp, cached }: ICall): Promise< IResponse<T> > {
    const authHeaders:{ [key: string]: string } = {};
    
    const token = Auth0Verrific.getToken() || localStorage.getItem('v+token');
    if (token) {
      authHeaders['authorization'] = `Bearer ${token}`;
    }
    Object.entries(headers || {}).forEach( ([key,value]) => {
      authHeaders[key] = value;
    } );
    
    const response:  IResponse<T> = await this.publicCall<T>({type, path, body, headers: authHeaders, forceHttp, cached });      
    
    if (response.statusCode === 401 || response.statusCode === 403) {
      handleUnauthorized(location.pathname);
    }

    return response;
  }

  public async publicCall<T>({ type, path, body, headers, forceHttp, cached }: ICall): Promise< IResponse<T> > {
    if (cached) {
      const cachedCall = this.cache.find(call => {
        return call.type === type &&
          call.path === path &&
          compare(call.body, body) &&
          compare(call.headers, headers) &&
          call.forceHttp === forceHttp;
      });
      if (cachedCall?.cachedResponse) {
        return cachedCall?.cachedResponse;
      }
    }

    let response: IResponse<T> = { statusCode: 999 };
    const readyHeaders = new Headers();
    
    let url = path;

    if (!this.isBodyNedded(type) && body) {
      url += this.parseQuery(body);
    }

    Object.entries(headers || {}).forEach( ([key,value]) => {
      readyHeaders.set(key,value);
    } );

    readyHeaders.set('Content-Type','application/json');
    readyHeaders.set('Accept','application/json');    
    const mock = this.getMock(type, path);
    if (mock) {
      response = await mock.fn({ type, path, body, headers, forceHttp });
    } else {
      try {
        const result = await fetch(PATH + url,{
          method: type,
          headers: readyHeaders,
          body: this.isBodyNedded(type) ? JSON.stringify(body) : undefined
        });
        
        if(type === 'HEAD' && result.headers.get('etag')){
          response.eTag = result.headers.get('etag');
        }
        try {
          /* A variable that is used to store the response of the call. */
          if(result.headers.get('content-type') === 'application/pdf'){
            response.files = await result.blob();
          }
          response.body = await result.json();
          response.rawBody = (JSON.stringify(response.body));
        } catch(e) {
          /** This console log is for debug purposes */
          console.log('body is not a json!');
        }
        response.statusCode = result.status;
  
      } catch(error){
        response.error = (error as Error).message;
      }
    }
    if (cached && response.statusCode < 400) {
      this.cache.push({ 
        type, 
        path, 
        body, 
        headers, 
        forceHttp, 
        cached,
        cachedResponse: response
      });
    }

    if (response.statusCode === 401) {
      toast('Unauthorized - on endpoint (' + path + ')', {
        type: 'error',
        onClose: () => {
          location.href = '/logout';
        }
      });
    } else {
      handleErrorGlobally(response);
    }
    return response;
  }

  private isBodyNedded(type: TRequestType): boolean {
    return [ 'POST','PUT', 'PATCH' ].includes(type);
  }
}

export default CommunicationService;