import './PrototypeExtenders';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Assets/fonts/fonts.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Translations/i18n';
import './Utils/SentryInit';
import './Utils/FrontEnvs';

interface CustomWindow extends Window {
  removeSplash: () => void
}

declare const window: CustomWindow;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
  ,
  document.getElementById('root'),
  () => {
    setTimeout(() => {
      window.removeSplash();
    },100);
  }
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
