import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

/* A function that returns an object with obecjects, each one of them contains 
  styles which are used to style Button component */
export const useStyles = makeStyles((theme: Theme) => ({
  primary: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    fontWeight: 'bold',
    color: '#FFFFFF',
    paddingTop: '4px',
    paddingBottom: '4px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);'
  },
  secondary: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    fontWeight: 400,
    color: '#595959',
    paddingTop: '4px',
    paddingBottom: '4px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  tertiary: {
    backgroundColor: `${theme.palette.tertiary.main} !important`,
    fontWeight: 'bold',
    color: '#FFFFFF',
    paddingTop: '4px',
    paddingBottom: '4px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  quartiary: {
    backgroundColor: `${theme.palette.quartiary.main} !important`,
    fontWeight: 'bold',
    color: '#808080',
    paddingTop: '4px',
    paddingBottom: '4px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },

  primaryDisabled: {
    backgroundColor: '#D3D3D3 !important',
    fontWeight: 'bold',
    opacity: 1,
    color: '#ABABAB !important',
    paddingTop: '4px',
    paddingBottom: '4px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);'
  },
  secondaryDisabled: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    fontWeight: 400,
    opacity: 0.5,
    color: '#595959',
    paddingTop: '4px',
    paddingBottom: '4px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  tertiaryDisabled: {
    backgroundColor: `${theme.palette.tertiary.main} !important`,
    fontWeight: 400,
    opacity: 0.8,
    color: '#FFFFFF',
    paddingTop: '4px',
    paddingBottom: '4px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  quartiaryDisabled: {
    backgroundColor: `${theme.palette.quartiary.main} !important`,
    fontWeight: 400,
    opacity: 0.8,
    color: '#808080',
    paddingTop: '4px',
    paddingBottom: '4px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}));